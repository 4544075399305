import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = ({
  data: {
    allMdx: { edges: posts },
  },
}) => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi, I'm Uku</h1>
    <p>
      I build product. I'm currently building{' '}
      <a href="https://transferwise.com/u/ukut">TransferWise</a>. These are my{' '}
      <a href="https://twitter.com/UTammet">twitter</a> and{' '}
      <a href="https://github.com/tankenstein">github</a>, but I mostly lurk.
    </p>
    <p>There's some writings below.</p>
    <ul className="articles">
      {posts.map(({ node: post }) => (
        <li key={post.id} className="article">
          <Link to={post.fields.slug}>
            <h2>{post.frontmatter.title}</h2>
            <small className="flex-between">
              <p>{post.frontmatter.date}</p>
              <p>{post.timeToRead} minute read</p>
            </small>
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query blogIndex {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
